import React from 'react'
import CallToAction from 'src/components/CallToAction'

import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

export const Content = () => {

  return (
    <ContentWrapper>
      <section className='pt-5 pb-lg-5 bg-white'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col col-lg-8'>
              <h3>Precisando resgatar investimentos que ainda não venceram?</h3>
              <p>
                Por meio do Mercado Secundário de Títulos é possível. Funciona assim: se você tiver, por exemplo, uma LCI com prazo de 24 meses e precisar do dinheiro antes do vencimento, poderá negociar e vendê-la para outra pessoa.
              </p>
              <p>Nosso time de analistas especializados busca no Mercado de Capitais as melhores oportunidades de investimentos
                para atender a diferentes perfis de investidores e seus objetivos.
              </p>
              <p>
                Além disso, o Mercado Secundário oferece oportunidades de fazer excelentes negócios, comprando de outros investidores títulos com prazo já decorrido, com taxas muito atrativas.
              </p>
              <p>
                Negocie a partir de R$1.000,00: CRI, CRA, Debêntures Incentivadas, LCI e LCA.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className='container pb-4'>
        <SideBar className='col-12 col-lg-4 px-0 px-lg-3'>
          <div className='position-sticky'>
            <CallToAction
              section='dobra_1'
              elementAction='click button'
              elementName='Abra sua conta e invista'
              sectionName='Precisando resgatar investimentos que ainda não venceram?'
              text='Abra sua conta e invista'
              chat={true}
              account={true}
            />
          </div>
        </SideBar>
      </div>
    </ContentWrapper>
  )
}
