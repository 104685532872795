import React from 'react'

import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'
import { Hero } from './sections/Hero/_index'
import { Content } from './sections/Content/_index'

const CanaisDeAtendimento = () => {
  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <Content />
    </Layout>
  )
}

export default CanaisDeAtendimento
